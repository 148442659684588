import React, { useState, useEffect, useRef } from "react";
import Stepper from "../components/Nav/Stepper";
import TicketingDetails from "../components/Event/TicketingDetails";
import * as api from "../api";
import CheckOut from "../components/Event/Checkout";
import { options as dateOptions } from "../utils/dateFormat";
import { options as timeOptions } from "../utils/timeFormat";
import ExpiryPopup from "../components/Utils/ExpiryPopup";
import { Prompt } from "react-router-dom";
import { ReactComponent as Cancel } from "../assets/images/cross.svg";
import Pay from "./../components/Event/Pay";
import GuestsInfo from "../components/Event/GuestsInfo";
import { external_platform, hardcoded_event } from "../constants";
import EmptyPageWithMessage from "../components/Utils/EmptyPageWithMessage";
import Loader from "../components/Utils/Loader";
import qs from "qs";
import { useGetUserCurrencyFromRegion } from "../utils/useGetUserCurrencyFromRegion";
import PaymentService from "../components/Payment/PaymentService";

const BookingPage = ({
  user = {},
  showLogin = () => {},
  setRegistrationHeader = () => {},
  history = {},
  match = {},
  handleRegistrationPopup = () => {},
  setActiveOrder = () => {},
  expiry = null,
  setExpiry = () => {},
  userLoading = true,
  isLoginVisible,
}) => {
  const [event, setEvent] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([
    {
      label: "Passes",
      subtitle: "Select your passes",
      name: "tickets",
      visitable: false,
    },
    {
      label: "Checkout",
      subtitle: "Select your add-ons",
      name: "checkout",
      visitable: false,
    },
    {
      label: "Pay",
      subtitle: "Select your payment method",
      name: "pay",
      visitable: false,
    },
  ]);
  const [tickets, setTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState({});
  let token = useRef(null);
  let ticketToken = useRef(null);
  const [cart, setCart] = useState({});
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ticketCount, setTicketCount] = useState(0);
  const [paymentData, setPaymentData] = useState({});
  const [paymentSubmitLoading, setPaymentSubmitLoading] = useState(false);
  const [paymentError, setPaymentError] = useState({});
  const [id] = useState(match.params.id);
  const [fetchError, setFetchError] = useState("");
  const [expired, setExpired] = useState(false);
  const [showRepalceOrderPopup, setShowReplaceOrderPopup] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [showReceipt, setShowReceipt] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [tempCart, setTempCart] = useState({});
  const [guests, setGuests] = useState([]);
  const [stepsLoaded, setStepsLoaded] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [nextAction, setNextAction] = useState({});
  const [ticketsPool, setTicketsPool] = useState({});
  const [termsAndConditions, setTermsAndConditions] = useState({});
  const expiryTimeout = useRef(null);
  const [showContent, setShowContent] = useState(false);
  const [enforceLogin, setEnforceLogin] = useState(false);
  const [lockTickets, setLockTickets] = useState(false);
  const { userCurrency, isLoading: userCurrencyLoading } =
    useGetUserCurrencyFromRegion();
  const isSubmitted = useRef(false);
  const [preferredRegion, setPreferredRegion] = useState({});

  useEffect(() => {
    //redirect to another platform for this event
    if (match?.params?.id == external_platform?.id) {
      window.location.replace(external_platform.redirect_url);
    }
    if (hardcoded_event !== null && hardcoded_event != match?.params?.id) {
      history.replace(`/booking/${hardcoded_event}`);
      window.location.reload();
    }
  }, [match]);

  //Cleanup for timer on page close
  useEffect(() => {
    return () => {
      if (expiryTimeout?.current) {
        clearInterval(expiryTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    ticketToken.current = qs.parse(history?.location?.search, {
      ignoreQueryPrefix: true,
    }).token;
    if (!userLoading && !userCurrencyLoading) {
      let fetchedEvent = {};
      let fetchedAddons = [];
      api.events.getEventById(id).then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let event = {
            title: response.data.name,
            description: response.data.description,
            image: response.data.thumbnail,
            date: new Date(response.data.startsAt).toLocaleDateString(
              [],
              dateOptions
            ),
            artists: response.data.artists,
            location: response.data.location,
            startsAt: new Date(response.data.startsAt).toLocaleTimeString(
              [],
              timeOptions
            ),
            id: response.data.pk,
            layoutImage: response.data.siteMap,
            type: response.data.reservationProcessType,
            isSeated: response?.data?.isSeated,
            eventKey: response?.data?.eventKey,
            regions: response?.data?.availableRegions?.map((region) => ({
              label: region?.name,
              value: region?.id,
              currency: region?.currency,
              skipMoto: region?.skipMoto,
              isDefault: region?.default,
              commission: region?.commissionRate,
            })),
          };
          const defaultRegion = event?.regions?.find(
            (item) => item?.isDefault == true
          );
          const urlRegion = event?.regions?.find(
            (item) =>
              item?.label?.toLowerCase() ==
              qs
                .parse(history?.location?.search, {
                  ignoreQueryPrefix: true,
                })
                ?.region?.toLowerCase()
          );
          const selectedRegion = urlRegion
            ? urlRegion
            : !response?.data?.autoSelectRegion
            ? defaultRegion
            : event?.regions?.find((item) => item?.currency == userCurrency) ||
              defaultRegion;
          setPreferredRegion({
            ...selectedRegion,
          });
          setLockTickets(
            response?.data?.isInPreRegister && !Boolean(ticketToken?.current)
          );
          if (response.data?.terms) {
            setTermsAndConditions({
              title: response.data?.terms?.content || "",
              terms: response.data?.terms?.terms?.map((item) => {
                return { ...item, checked: false };
              }),
            });
          }
          fetchedEvent = event;
          setEvent(event);
          listTicketTiers(
            !response?.data?.isInPreRegister ||
              (response?.data?.isInPreRegister &&
                Boolean(ticketToken?.current)),
            selectedRegion
          );
        }
        api.events
          .addons(id)
          .then((response) => {
            setAddons(() => {
              let arr = response.data.results.map((addon) => {
                return {
                  name: addon.name,
                  id: addon.id,
                  type: addon.addonType,
                  description: addon.description,
                  price: addon.price / 100,
                  collapsed: true,
                };
              });
              return arr;
            });
            fetchedAddons = response.data.results;
          })
          .finally(() => {
            handleDynamicSteps(fetchedEvent, fetchedAddons);
          });
      });
    }
    return () => {
      setRegistrationHeader({});
      if (token.current && !isSubmitted.current) {
        api.cart.cancelCart(token.current);
      }
    };
  }, [userLoading, userCurrencyLoading]);

  const handleCurrencyChange = (currency) => {
    setPreferredRegion(currency);
    setSelectedTickets([]);
    listTicketTiers(!lockTickets, currency);
  };

  const listTicketTiers = (canView = true, currency = "") => {
    if (canView) {
      setLoading(true);
      api.events
        .ticketTiers(id, ticketToken?.current, currency?.value)
        .then((response) => {
          setLoading(false);
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            //if user has a token and still getting empty tiers, then token is invalid
            if (response.data.results.length === 0 && ticketToken?.current) {
              setLockTickets(true);
              return;
            }

            setTickets(() => {
              let tickets = response.data.results.map((ticket) => {
                return {
                  name: ticket.name,
                  id: ticket.pk,
                  price: ticket.price / 100,
                  currency: ticket.region.currency,
                  state: getTicketState(ticket),
                  description: ticket.description,
                  category: ticket.category,
                  commissionRate: ticket?.region?.commissionRate,
                };
              });
              return tickets;
            });
            setShowContent(true);
          }
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            showLogin(true);
            setEnforceLogin(true);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (
      Object.keys(user)?.length == 0 &&
      !userLoading &&
      enforceLogin &&
      !isLoginVisible
    ) {
      history.replace("/");
    } else if (
      Object.keys(user)?.length != 0 &&
      enforceLogin &&
      !isLoginVisible
    ) {
      listTicketTiers(
        !lockTickets || (lockTickets && ticketToken),
        preferredRegion
      );
    }
  }, [userLoading, enforceLogin, user, isLoginVisible, lockTickets]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    let allSteps = [...steps];
    let checkoutStep = allSteps.filter((step) => step.name === "checkout");
    checkoutStep = checkoutStep[0];
    let index = allSteps.findIndex((element) => element.name === "checkout");
    if (Object.keys(paymentData).length !== 0) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
    allSteps[index] = checkoutStep;
    setSteps(allSteps);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [cart, expiry, paymentData]);

  useEffect(() => {
    let allSteps = [...steps];
    for (let i = 0; i < allSteps.length; i++) {
      if (allSteps[i].name === "checkout" || allSteps[i].name === "pay") {
        if (Object.keys(cart).length !== 0) {
          allSteps[i].visitable = true;
        } else {
          allSteps[i].visitable = false;
        }
      }
    }
    setSteps(allSteps);
  }, [activeStep, cart]);

  const getTicketState = (ticket) => {
    let now = new Date().getTime();
    if (
      ticket?.salesStartsAt &&
      new Date(ticket.salesStartsAt).getTime() > now
    ) {
      if (!ticket.showOnSaleTime) {
        return { label: "Coming Soon", type: "coming-soon" };
      } else {
        // Return the formatted date of when ticket tiers will be available
        return {
          label:
            "On Sale: " +
            new Date(ticket.salesStartsAt).toLocaleString("en-GB", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            }) +
            " " +
            new Date(ticket.salesStartsAt).toLocaleString("default", {
              hour: "numeric",
              minute: "numeric",
            }) +
            " ",
          type: "coming-soon",
        };
      }
    } else if (
      ticket?.salesEndsAt &&
      new Date(ticket.salesEndsAt).getTime() < now
    ) {
      return { label: "", type: "expired" };
    } else if (ticket.isSoldOut) {
      return { label: "Sold Out", type: "sold-out" };
    } else {
      return null;
    }
  };

  const handleUnload = () => {
    if (token.current && !isSubmitted.current) {
      api.cart.cancelCart(token.current);
    }
  };

  const handleBeforeUnload = (event) => {
    if (Object.keys(cart).length !== 0 && expiry - new Date().getTime() > 0) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return "";
    } else {
      return;
    }
  };

  const nextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleStepClick = (name) => {
    let index = steps.findIndex((item) => item.name === name);
    if (index !== -1) {
      setActiveStep(index);
    }
  };

  const handleTicketClick = (id, name, price, type, seat = "", currency) => {
    setSelectedTickets((current) => {
      let currentCopy = { ...current };
      if (type === "subtract" && currentCopy[id]) {
        if (currentCopy[id].count - 1 <= 0) {
          delete currentCopy[id];
        } else {
          let currentTicketCopy = { ...currentCopy[id] };
          currentTicketCopy.count = currentTicketCopy.count - 1;
          if (event?.isSeated) {
            currentTicketCopy.seats = currentTicketCopy.seats.filter(
              (item) => item !== seat
            );
          }
          currentCopy[id] = currentTicketCopy;
        }
      } else if (type === "add" && currentCopy[id]) {
        let currentTicketCopy = { ...currentCopy[id] };
        currentTicketCopy.count = currentTicketCopy.count + 1;
        if (event?.isSeated) {
          currentTicketCopy.seats = currentTicketCopy.seats.concat(seat);
        }
        currentCopy[id] = currentTicketCopy;
      } else if (type === "add" && !currentCopy[id]) {
        currentCopy[id] = { name, price, count: 1, currency };
        if (event?.isSeated) {
          currentCopy[id].seats = [seat];
        }
      }
      return currentCopy;
    });
  };

  const handleTicketsFetch = () => {
    if (Object.keys(selectedTickets).length === 0) {
      return;
    }
    if (Object.keys(user).length === 0 && event.type === "full") {
      showLogin(true);
      setNextAction({ action: "fetchTickets", payload: null });
      return;
    }
    setLoading(true);
    let items = [];
    for (const tier in selectedTickets) {
      let item = {
        ticketTier: tier,
        quantity: selectedTickets[tier].count,
      };
      if (event?.isSeated) {
        item.seats = selectedTickets[tier].seats;
      }
      items = items.concat(item);
    }
    setFetchError("");
    api.cart
      .addTicketsToCart(items, id, token.current)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setExpiry(null);
          token.current = response.data.token;
          setCart(() => {
            let obj = {};
            let items = response.data.cart.items;
            for (let i = 0; i < items.length; i++) {
              let tier = {
                id: items[i].id,
                quantity: items[i].quantity,
                name: selectedTickets[items[i].ticketTier].name,
                price: selectedTickets[items[i].ticketTier].price,
                currency: selectedTickets[items[i].ticketTier].currency,
                ticketTier: items[i].ticketTier,
              };
              if (event?.isSeated) {
                tier.seats = items[i].seats;
              }
              obj[items[i].ticketTier] = tier;
            }
            return obj;
          });
          let newExpiry = new Date(response.data.cart.expiresAt).getTime();
          let now = new Date().getTime();
          /* addTimerToNavbar(expiry); */
          if (event.type === "full") {
            setActiveOrder(true);
          }
          setExpiry(newExpiry);
          setLoading(false);
          nextStep();
          setRegistrationHeader({
            now,
            expiry: newExpiry,
            eventTitle: event.title,
            ticketCount,
          });
          if (expiryTimeout?.current) {
            clearInterval(expiryTimeout.current);
          }
          if (Object.keys(user).length === 0) {
            showLogin(true);
          }
          expiryTimeout.current = setInterval(() => {
            if (newExpiry - new Date().getTime() <= 0) {
              setExpired(true);
              cancelCart();
            }
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          setFetchError(error.response.data.nonFieldErrors[0]);
        }
      });
  };

  const handlePaymentSubmit = (paymentMethod, paymentGroup = null) => {
    setPaymentError({});
    if (expired) {
      return;
    }
    if (Object.keys(user).length === 0) {
      showLogin(true);
      let payload = {
        paymentMethod,
        paymentGroup,
      };
      setNextAction({ action: "pay", payload });
    } else {
      isSubmitted.current = true;
      setPaymentSubmitLoading(true);
      let addonItems = [];
      for (const item in selectedAddons) {
        addonItems = addonItems.concat({
          quantity: selectedAddons[item].count,
          addon: item,
        });
      }
      if (event.type === "full") {
        let items = [];
        let reservationHead = user.pk;
        for (let i = 0; i < guests.length; i++) {
          items.push({
            customer: guests[i].pk,
            ticketTier: guests[i].ticket.ticketTier,
          });
        }
        api.reservations
          .submitReservation(
            reservationHead,
            items,
            addonItems,
            event.id,
            preferredRegion?.skipMoto ? "card" : paymentMethod,
            paymentGroup === "pwf" ? true : false,
            token.current,
            ticketToken.current
          )
          .then((response) => {
            if (
              response.status &&
              response.status >= 200 &&
              response.status < 300
            ) {
              if (!preferredRegion?.skipMoto && paymentMethod !== "valu") {
                setPaymentData({
                  type: paymentMethod,
                  url: response.data.cardPaymentUrl,
                  reference: response.data.billReference,
                  paymentSolution: response?.data?.paymentSolution,
                  orderId: response.data.order,
                  clientSecret: response?.data?.extraData?.clientSecret,
                });
              } else {
                history.push(`/orders/${response.data.order}`);
              }
              if (paymentMethod === "kiosk") {
                history.replace(`/orders/${response.data.order}`);
              }
            } else {
              setPaymentSubmitLoading(false);
            }
          })
          .catch((error) => {
            isSubmitted.current = false;
            if (
              error?.response?.status === 400 &&
              error?.response?.data?.nonFieldErrors
            ) {
              setPaymentError({
                message: error.response.data.nonFieldErrors[0],
                paymentMethod,
                paymentGroup,
                action: handleStepClick,
              });
            } else {
              setPaymentError({
                message: "Something went wrong while processing your order",
                paymentMethod,
                paymentGroup,
              });
            }
            setPaymentSubmitLoading(false);
          });
      } else {
        api.cart
          .payCart(addonItems, token.current, paymentMethod)
          .then((response) => {
            if (
              response.status &&
              response.status >= 200 &&
              response.status < 300
            ) {
              setPaymentData({
                type: paymentMethod,
                url: response.data.cardPaymentUrl,
                reference: response.data.billReference,
                paymentSolution: response?.data?.paymentSolution,
                orderId: response.data.order,
                clientSecret: response?.data?.extraData?.clientSecret,
              });
              if (paymentMethod === "kiosk") {
                history.replace(`/orders/${response.data.order}`);
              }
            } else {
              setPaymentSubmitLoading(false);
            }
          })
          .catch(() => {
            setPaymentError({
              message: "Something went wrong while processing your order",
              paymentMethod,
              paymentGroup,
            });
            setPaymentSubmitLoading(false);
          });
      }
    }
  };

  const handleExpiryRetry = () => {
    setActiveStep(0);
    setExpired(false);
  };

  const handleReplaceOrder = () => {
    setShowReplaceOrderPopup(false);
    handleTicketsFetch();
  };

  const cancelCart = () => {
    api.cart.cancelCart(token.current);
    setActiveOrder(false);
    setExpiry(null);
    handleRegistrationPopup("login", false);
    handleRegistrationPopup("register", false);
    handleRegistrationPopup("forgotPassword", false);
    setPaymentData({});
    setRegistrationHeader({});
    setShowReplaceOrderPopup(false);
    setCart({});
    setTicketsPool({});
    setGuests([]);
    setTempCart({});
    setSelectedTickets({});
    if (expiryTimeout?.current) {
      clearInterval(expiryTimeout.current);
    }
    token.current = null;
    handleStepClick("tickets");
    setShowCancelConfirmation(false);
  };

  useEffect(() => {
    if (
      Object.keys(user).length === 0 &&
      event.type === "full" &&
      activeStep > 0
    ) {
      cancelCart();
    } else if (Object.keys(user).length !== 0) {
      if (Object.keys(nextAction).length !== 0) {
        let action = { ...nextAction };
        let payload = action.payload !== null ? { ...action.payload } : null;
        setNextAction({});
        handleNextAction(action.action, payload);
      }
    }
  }, [user, nextAction]);

  const handleNextAction = (action, payload = null) => {
    switch (action) {
      case "fetchTickets":
        handleTicketsFetch();
        break;
      case "pay":
        handlePaymentSubmit(payload.paymentMethod, payload.paymentGroup);
        break;
      default:
        break;
    }
  };

  const handleDynamicSteps = (fetchedEvent, fetchedAddons) => {
    let stepsCopy = [...steps];
    if (fetchedEvent.type === "full") {
      let check = steps.filter((step) => step.name === "guests");
      if (check.length === 0) {
        let step = {
          label: "Guests' Info",
          subtitle: "Enter your group information",
          name: "guests",
          visitable: true,
        };
        stepsCopy.splice(1, 0, step);
      }
    }
    if (fetchedAddons.length === 0) {
      let index = stepsCopy.findIndex((item) => item.name === "checkout");
      if (index !== -1) {
        stepsCopy.splice(index, 1);
      }
    }
    setSteps(stepsCopy);
    setStepsLoaded(true);
  };

  if (Object.keys(event)?.length == 0) {
    return (
      <div className="page-loader-container">
        <Loader />
      </div>
    );
  }

  if (lockTickets) {
    return (
      <EmptyPageWithMessage
        message={
          "Sandbox 2025 tickets will be on sale soon. For those who have registered for Pre-Sale, keep an eye on your e-mail inbox for your exclusive access."
        }
      />
      
    );
  }

  return (
    <React.Fragment>
      {showContent && (
        <div
          className="event-page"
          onClick={(e) => {
            if (showReceipt) {
              setShowReceipt(false);
            }
          }}
        >
          <div className="mobile-header">
            <div className="mobile-header__title">
              <h3 className="mobile-header__title-text">
                {steps[activeStep].label}
              </h3>
              {Object.keys(cart).length !== 0 && (
                <div
                  className="mobile-header__cancel"
                  onClick={() => setShowCancelConfirmation(true)}
                >
                  <Cancel className="mobile-header__cancel-icon" />
                </div>
              )}
            </div>
            <p className="mobile-header__subtitle">
              {steps[activeStep].subtitle}
            </p>
          </div>
          {stepsLoaded && false && (
            <Stepper
              steps={steps}
              active={activeStep}
              handleStepClick={/* handleStepClick */ () => {}}
            />
          )}
          {steps[activeStep]?.name === "tickets" && (
            <TicketingDetails
              tickets={tickets}
              setTickets={setTickets}
              layoutImage={event.layoutImage}
              selectedTickets={selectedTickets}
              setSelectedTickets={setSelectedTickets}
              cart={cart}
              handleTicketClick={handleTicketClick}
              handleTicketsFetch={() => {
                if (Object.keys(cart).length === 0) {
                  handleTicketsFetch();
                } else {
                  setShowReplaceOrderPopup(true);
                }
              }}
              handleRetry={handleTicketsFetch}
              loading={loading}
              ticketCount={ticketCount}
              setTicketCount={setTicketCount}
              fetchError={fetchError}
              setFetchError={setFetchError}
              expiry={expiry}
              event={event}
              nextStep={nextStep}
              selectedCurrency={preferredRegion}
              handleCurrencyChange={handleCurrencyChange}
            />
          )}
          {steps[activeStep]?.name === "checkout" && (
            <CheckOut
              user={user}
              id={id}
              event={event}
              tickets={cart}
              expiry={expiry}
              selectedAddons={selectedAddons}
              setSelectedAddons={setSelectedAddons}
              subtotal={subtotal}
              setSubtotal={setSubtotal}
              showReceipt={showReceipt}
              setShowReceipt={setShowReceipt}
              grandTotal={grandTotal}
              setGrandTotal={setGrandTotal}
              nextStep={nextStep}
              cancelOrder={() => setShowCancelConfirmation(true)}
              tempCart={tempCart}
              addons={addons}
              setAddons={setAddons}
              editGuests={guests.length > 0}
              handleStepClick={handleStepClick}
              currency={preferredRegion?.currency}
              commission={preferredRegion?.commission}
            />
          )}
          {steps[activeStep]?.name === "pay" && (
            <Pay
              user={user}
              event={event}
              tickets={cart}
              expiry={expiry}
              selectedAddons={selectedAddons}
              subtotal={subtotal}
              setSubtotal={setSubtotal}
              showReceipt={showReceipt}
              setShowReceipt={setShowReceipt}
              grandTotal={grandTotal}
              setGrandTotal={setGrandTotal}
              handlePaymentSubmit={handlePaymentSubmit}
              handleStepClick={handleStepClick}
              cancelOrder={() => setShowCancelConfirmation(true)}
              tempCart={tempCart}
              showEditAddons={addons.length > 0}
              editGuests={guests.length > 0}
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              currency={preferredRegion?.currency}
              skipPayment={preferredRegion?.skipMoto}
              commission={preferredRegion?.commission}
            />
          )}

          {steps[activeStep]?.name === "guests" && (
            <GuestsInfo
              user={user}
              event={event}
              tickets={cart}
              expiry={expiry}
              selectedAddons={selectedAddons}
              subtotal={subtotal}
              setSubtotal={setSubtotal}
              showReceipt={showReceipt}
              setShowReceipt={setShowReceipt}
              grandTotal={grandTotal}
              setGrandTotal={setGrandTotal}
              nextStep={nextStep}
              jumpToStep={handleStepClick}
              newCart={tempCart}
              setNewCart={setTempCart}
              guestsMapper={guests}
              setGuestsMapper={setGuests}
              cancelOrder={() => setShowCancelConfirmation(true)}
              ticketsPool={ticketsPool}
              setTicketsPool={setTicketsPool}
              currency={preferredRegion?.currency}
              commission={preferredRegion?.commission}
            />
          )}

          <PaymentService
            type={paymentData?.paymentSolution}
            onClose={() => {
              isSubmitted.current = false;
              setPaymentData({});
            }}
            setLoading={setPaymentSubmitLoading}
            stripeClientSecret={paymentData?.clientSecret}
            isStripeSetup={true}
            stripeRedirectUrl={`/payment-confirmation?merchant_order_id=${paymentData?.orderId}&solution=stripe`}
            cardPaymentUrl={paymentData?.url}
            loading={paymentSubmitLoading}
            loadingMessage={
              preferredRegion?.skipMoto
                ? ""
                : "we're getting your payment form ready"
            }
          />

          {expired && (
            <ExpiryPopup
              title="Timer expired"
              message="Looks like your timer has expired"
              actionMessage="Do you wish to try again?"
              handleBtn1Click={handleExpiryRetry}
              btn1="Try Again"
              closable={false}
            />
          )}
          {showRepalceOrderPopup && (
            <ExpiryPopup
              title="Complete standing order"
              message="You currently have a standing purchase, by making a new order you will lose your fetched tickets."
              actionMessage="Are you sure you want to override your order? "
              handleBtn1Click={() => setShowReplaceOrderPopup(false)}
              handleBtn2Click={handleReplaceOrder}
              btn1="No, Complete standing order"
              btn2="Yes, override standing order"
              closable={true}
              close={() => setShowReplaceOrderPopup(false)}
              expiry={expiry}
            />
          )}
          {showCancelConfirmation && (
            <ExpiryPopup
              title="Timer"
              message="By cancelling your order you will lose your fetched passes."
              actionMessage="Are you sure you want to cancel your order? "
              handleBtn1Click={() => setShowCancelConfirmation(false)}
              handleBtn2Click={cancelCart}
              btn1="No, Dont't cancel my order"
              btn2="Yes, Cancel my order"
              closable={true}
              close={() => setShowCancelConfirmation(false)}
              expiry={expiry}
            />
          )}
          {Object.keys(paymentError).length !== 0 && (
            <ExpiryPopup
              title="Timer"
              variant="error"
              message={paymentError.message}
              handleBtn1Click={
                paymentError.action
                  ? () => {
                      paymentError.action("guests");
                      setPaymentError({});
                    }
                  : ""
              }
              handleBtn2Click={() => setPaymentError({})}
              btn1={paymentError.action ? "Edit Guests" : ""}
              btn2="Close"
              closable={true}
              close={() => setPaymentError({})}
              expiry={expiry}
            />
          )}
          {Object.keys(paymentData).length === 0 &&
            Object.keys(cart).length !== 0 &&
            expiry - new Date().getTime() > 0 &&
            !isSubmitted.current && (
              <Prompt
                when={
                  Object.keys(cart).length !== 0 &&
                  expiry - new Date().getTime() > 0
                }
                message="If you leave this page your order will be cancelled"
              />
            )}
        </div>
      )}
    </React.Fragment>
  );
};

export default BookingPage;
