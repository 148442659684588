import React, { useEffect } from "react";
import Loader from "../Utils/Loader";
import qs from "qs";
import * as api from "./../../api";

const PaymentConfirmationRedirect = ({ location, history }) => {
  useEffect(() => {
    const orderId = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_order_id;
    const solution = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).solution;
    window.dataLayer = window.dataLayer || [];

    if (!orderId) {
      history.replace("/");
    } else {
      if (solution == "stripe") {
        api.reservations
          .checkPayment(orderId)
          .then((res) => {  
            window.dataLayer.push({
              event: "paymentStatus",
              //If undefined or false then it is a failed payment
              paymentStatus: res?.data?.success ? true : false,
            });

            history.replace(`/orders/${orderId}`);
          })
          .catch(() => {
            history.replace(`/orders/${orderId}`);
          });
      } else {
        history.replace(`/orders/${orderId}`);
      }
    }
  }, []);

  return (
    <div className="payment-redirect">
      <div className="payment-redirect__loader">
        <Loader />
      </div>
    </div>
  );
};

export default PaymentConfirmationRedirect;
